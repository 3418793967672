const AppConsts = {
  
    // For Production
     apiBaseUrl: "https://api.panchalohamsilverjewellery.com/Ecommerce",

    // For Development
    //apiBaseUrl: "https://localhost:7050/Ecommerce",

    notAvailable: "na",
    productImagePath: 'https://ecommerce.api.makinus.com/Ecommerce/ProductImage/Product/%s/Image/%s/Company/%s',
    productVariantImagePath: 'https://ecommerce.api.makinus.com/Ecommerce/Product/%s/Image/Variant/%s/Company/%s',
    categoryFilterTag: 'filter_category',
    filterTag: 'filter_tag',
    errorMsgStyle: {
      color: "red",
      fontSize: "14px",
    },
    DATE_FORMAT_DD_MM_YYYY_WITH_SLASH: "DD/MM/YYYY",
    DATE_FORMAT_MMMM_D_COMMA_YYYY_AT_H_MM_A: "MMMM D, YYYY [at] h:mm a",
    base64ImagePrefix: "data:image/jpg;base64,",
    productNameDisplayLimit: 60
  };
  export default AppConsts;
  